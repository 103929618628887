export const convertJsonToHtml = (jsonString) => {
    try {
        const jsonData = JSON.parse(jsonString);
        
        // Recursive function to generate HTML from objects/arrays
        const generateTable = (data) => {
            if (!Array.isArray(data)) return '';

            let tableHtml = '<table border="1" style="width:100%;border-collapse:collapse;text-align:left;">';

            // Extract headers dynamically
            const allKeys = new Set();
            data.forEach(item => {
                if (typeof item === 'object' && item !== null) {
                    Object.keys(item).forEach(key => allKeys.add(key));
                }
            });

            // Table headers
            tableHtml += '<tr>';
            allKeys.forEach(header => {
                tableHtml += `<th style="padding:8px;border:1px solid black;">${header.replace('_', ' ')}</th>`;
            });
            tableHtml += '</tr>';

            // Table rows
            data.forEach(item => {
                tableHtml += '<tr>';
                allKeys.forEach(key => {
                    let cellValue = item[key];

                    if (Array.isArray(cellValue)) {
                        // Handle nested arrays
                        tableHtml += `<td style="padding:8px;border:1px solid black;">${generateTable(cellValue)}</td>`;
                    } else if (typeof cellValue === 'object' && cellValue !== null) {
                        // Handle nested objects
                        tableHtml += '<td style="padding:8px;border:1px solid black;">';
                        Object.entries(cellValue).forEach(([nestedKey, nestedValue]) => {
                            tableHtml += `<b>${nestedKey.replace('_', ' ')}:</b> ${nestedValue}<br/>`;
                        });
                        tableHtml += '</td>';
                    } else {
                        // Standard values
                        tableHtml += `<td style="padding:8px;border:1px solid black;">${cellValue !== undefined ? cellValue : ''}</td>`;
                    }
                });
                tableHtml += '</tr>';
            });

            tableHtml += '</table>';
            return tableHtml;
        };

        // Identify the first array in the JSON structure
        const findFirstArray = (obj) => {
            if (Array.isArray(obj)) return obj;
            if (typeof obj === 'object' && obj !== null) {
                for (const key in obj) {
                    const result = findFirstArray(obj[key]);
                    if (result) return result;
                }
            }
            return null;
        };

        const mainArray = findFirstArray(jsonData);
        if (!mainArray) return '<p style="color: red;">No array found in the JSON data.</p>';

        return generateTable(mainArray);
    } catch (error) {
        return '<p style="color: red;">Invalid JSON input. Please correct it and try again.</p>';
    }
};


export const downloadHtmlFile = (htmlContent) => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'converted.html';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
