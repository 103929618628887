import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
const PrivacyPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div class="w3-row-padding mar-top-80">
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <h3 class="">PRIVACY POLICY</h3>
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
            <div class="w3-row-padding" style={{ margin: '14px 0px 100px 0px' }}>
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
                
<b>1. Introduction</b>
<p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you visit our website https://databeautifier.com/. By using this website, you agree to the terms of this policy.</p>

<b>2. Information We Collect</b>

<p>Personal Information: If you contact us via forms or email, We may collect personal information such as your name, email address, phone number, and any other details you provide voluntarily.
Non-Personal Information: We may also collect non-personal information, such as browser type, IP address, pages visited, and the time spent on the website, through cookies and analytics tools.</p>
<b>3. How We Use Your Information</b>

<p>To Communicate: We use your personal information to respond to inquiries, provide services, and keep you updated about projects or new content.
To Improve the Website: Non-personal data helps us understand how visitors use our website, enabling us to improve its functionality and user experience.
To Provide Services: If you hire us for a project, We'll use your information to communicate, deliver work, and manage our professional relationship.</p>
<b>4. Sharing Your Information</b>

<p>We respect your privacy and do not sell, rent, or share your personal information with third parties, except under the following circumstances:</p>

<p>Service Providers: We may share your information with trusted third-party service providers such as hosting services or email platforms to assist with running the website or delivering services to you.
Legal Requirements: If required by law, We may disclose your information to comply with legal obligations, protect our rights, or respond to lawful requests.</p>
<b>5. Cookies and Tracking Technologies</b>

<p>This website uses cookies and similar technologies to enhance your browsing experience. Cookies are small files stored on your device that help track user preferences and improve website performance.</p>

<p>Analytics Tools: We use tools like Google Analytics to gather information about how users interact with the site. This data helps me optimize the site and content.
You can choose to disable cookies through your browser settings, though this may affect some functionality of the website.</p>
<b>6. Data Security</b>

<p>We take appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, please note that no method of data transmission over the internet is entirely secure, and I cannot guarantee absolute security.</p>

<b>7. Third-Party Links</b>

<p>This website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. Please review the privacy policies of those websites before sharing any personal information.</p>

<b>8. Your Rights</b>

<p>You have the right to:

Access and request a copy of your personal information.
Correct any inaccuracies in your information.
Request the deletion of your personal data.
Withdraw consent where applicable.
If you would like to exercise any of these rights, please contact us at beautifierdata@gmail.com.</p>

<b>9. Changes to This Policy</b>

<p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page with a new effective date. I encourage you to review this page periodically to stay informed about how we protect your information.</p>

<b>10. Contact</b>

<p>If you have any questions or concerns about this Privacy Policy, please contact me at: beautifierdata@gmail.com</p>

              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PrivacyPage;
