export const convertJsonToString = (jsonData) => {
    try {
        const formattedString = JSON.stringify(JSON.stringify(JSON.parse(jsonData)));
        return { convertedData: formattedString, isError: false };
    } catch (error) {
        return { convertedData: "Invalid JSON", isError: true };
    }
};

export const convertStringToJson = (stringData) => {
    try {
        const jsonData = JSON.parse(JSON.parse(stringData));
        return { convertedData: JSON.stringify(jsonData, null, 2), isError: false };
    } catch (error) {
        return { convertedData: "Invalid String Format", isError: true };
    }
};
