import React, { useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../css/Base64.css';
import { handleConvert } from '../Conversion/Base64';
import { copyToClipboard } from '../Helpers/CommonHelpers';

const ImageToBase64 = () => {
  const [base64Image, setBase64Image] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const [error, setError] = useState('');

  const validateImageFile = (file) => {
    if (file && file.type.startsWith('image/')) {
      setError('');
      setSelectedFile(file);
    } else {
      setError('Only image files are allowed.');
      setSelectedFile(null);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateImageFile(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    validateImageFile(file);
  };

  const handleConvertWrapper = () => {
    if (!selectedFile) {
      setError('Please select a valid image file.');
      return;
    }
    handleConvert(selectedFile, setBase64Image, setIsConverting);
  };

  const handleCopyWrapper = () => copyToClipboard(base64Image);

  return (
    <div>
      <Header />

      <div className="container">

        <h1 className="title-heading" style={{marginTop:"50px"}}>
        Convert Images to Base64
        </h1>

        <p className="title-subheading">
        Easily convert your images into Base64 code with Data Beautifier's Free Image to Base64 Conversion Online Tool. Our Best Image to Base64 Conversion Online Tool is fast, accurate, and designed to handle all image types. With the Simple Image to Base64 Converter Tool, anyone can encode images into Base64 format without requiring technical expertise. The Easy Image to Base64 Converter Tool ensures a seamless experience—just upload your image and get the encoded result instantly. Whether you're a developer or a designer, our tool saves time and simplifies your workflow. Try Data Beautifier today for free and efficient conversions!
        </p>

        <div className="uploadSection">
          <div
            className="dropZone"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              accept="image/*"
              id="fileUpload"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label
              htmlFor="fileUpload"
              className={`uploadButton ${selectedFile ? 'file-selected' : ''}`}
            >
              <span>{selectedFile ? selectedFile.name : 'Choose Image or Drag Here'}</span>
            </label>
          </div>
          {error && <div className="error">{error}</div>}
          <button
            onClick={handleConvertWrapper}
            className="convertButton"
            disabled={!selectedFile || isConverting}
          >
            Convert to Base64
          </button>
        </div>

        <div className="outputSection">
          <textarea
            rows="20"
            className="textArea"
            value={base64Image}
            readOnly
            placeholder="Base64 URL will appear here..."
          />
          <button onClick={handleCopyWrapper} className="copyButton">
            Copy
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ImageToBase64;
