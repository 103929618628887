import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard';
import AboutPage from '../Pages/AboutPage';
import FAQPage from '../Pages/FAQPage';
import PrivacyPage from '../Pages/PrivacyPage';
import LoginPage from '../Pages/LoginPage';
import ImageToBase64 from '../Pages/ImageToBase64';
import Base64ToImage from '../Pages/Base64ToImage';
import JsonToXml from '../Pages/JsonToXml';
import HtmlFormatter from '../Pages/HtmlFormatter';
import XmlFormatter from '../Pages/XmlFormatter';
import TitleManager from '../Helpers/TitleManager';
import XmlToJson from '../Pages/XmlToJson';
import JsonMinifier from '../Pages/JsonMinifier';
import XmlMinifier from '../Pages/XmlMinifier';
import JsonToString from '../Pages/JsonToString';
import StringToJson from '../Pages/StringToJson';
import JsonToHtml from '../Pages/JsonToHtml';
import HtmlMinifier from '../Pages/HtmlMinifier';
import Base64ToAscii from '../Pages/Base64ToAscii';
import AsciiToBase64 from '../Pages/AsciiToBase64';

const AppRoutes = () => {
  return (
    <Router>
      <TitleManager />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/base64-to-image-conversion" element={<Base64ToImage />} />
        <Route path="/image-to-base64-conversion" element={<ImageToBase64 />} />
        <Route path="/json-minifier" element={<JsonMinifier />} />
        <Route path="/xml-minifier" element={<XmlMinifier/>} />
        <Route path="/json-to-xml-convertor" element={<JsonToXml />} />
        <Route path="/xml-to-json-convertor" element={<XmlToJson/>} />
        <Route path="/html-formatter" element={<HtmlFormatter />} />
        <Route path="/xml-formatter" element={<XmlFormatter />} />
        <Route path="/json-to-string-convertor" element={<JsonToString />} />
        <Route path="/string-to-json-convertor" element={<StringToJson />} />
        <Route path="/json-to-html-convertor" element={<JsonToHtml />} />
        <Route path="/html-minifier" element={<HtmlMinifier/>} />
        <Route path="/base64-to-ascii-conversion" element={<Base64ToAscii />} />
        <Route path="/ascii-to-base64-conversion" element={<AsciiToBase64 />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;