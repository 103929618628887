import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const TitleManager = () => {
  const location = useLocation();

  const routeMeta = {
    "/about": {
      title: "About Us - Data Beautifier",
      description: "Learn more about Data Beautifier and our mission to simplify data formatting.",
      keyword: "data beautifier about us, data beautifier about"
    },
    "/faq": {
      title: "FAQ - Data Beautifier",
      description: "Find answers to frequently asked questions about our data formatting tools.",
      keyword: "data beautifier faq"
    },
    "/privacy": {
      title: "Privacy Policy - Data Beautifier",
      description: "Read our privacy policy to understand how we handle your data securely.",
      keyword: "data beautifier privacy policy, data beautifier privacy, data beautifier policy"
    },
    "/base64-to-image-conversion": {
      title: "Base64 to Image Conversion - Data Beautifier",
      description: "Convert Base64 encoded strings back into images easily and quickly.",
      keyword: "image converter, image online converter, convert image, convert image online, online image converter, base64 to image converter, base64 to image online converter, convert base64 to image, convert base64 to image online, online base64 to image converter"
    },
    "/image-to-base64-conversion": {
      title: "Image to Base64 Conversion - Data Beautifier",
      description: "Convert images into Base64 encoded strings for easy storage and sharing.",
      keyword: "base64 converter, base64 online converter, convert base64, convert base64 online, online base64 converter, image to base64 converter, image to base64 online converter, convert image to base64, convert image to base64 online, online image to base64 converter"
    },
    "/json-minifier": {
      title: "JSON Minifier - Data Beautifier",
      description: "Minify JSON data instantly while keeping it readable and valid.",
      keyword: "json minifier, json minifier online, online json minifier, minify json online, minify json, best online json minifier, json cleaner"
    },
    "/xml-minifier": {
      title: "XML Minifier - Data Beautifier",
      description: "Compress XML data by removing unnecessary spaces and formatting.",
      keyword: "xml minifier, xml minifier online, online xml minifier, minify xml online, minify xml, best online xml minifier, xml cleaner"
    },
    "/json-to-xml-convertor": {
      title: "JSON to XML Converter - Data Beautifier",
      description: "Convert structured JSON data into well-formatted XML with ease.",
      keyword: "json converter, json online converter, convert json, convert json online, online json converter, "
    },
    "/xml-to-json-convertor": {
      title: "XML to JSON Converter - Data Beautifier",
      description: "Easily transform XML data into structured JSON format.",
      keyword: "xml converter, xml online converter, convert xml, convert xml online, online xml converter"
    },
    "/xml-formatter": {
      title: "XML Formatter - Data Beautifier",
      description: "Format and beautify XML data for better readability.",
      keyword: "xml formatter, xml formatter online, xml beautifier, xml beautifier online, xml beautify, beautify xml, beautify xml online, format xml, format xml online"
    },
    "/html-formatter": {
      title: "HTML Formatter - Data Beautifier",
      description: "Beautify and format messy HTML code instantly.",
      keyword: "html formatter, html formatter online, html beautifier, html beautifier online, html beautify, beautify html, beautify html online, format html, format html online"
    },
    "/json-to-string-convertor": {
      title: "JSON to String Converter - Data Beautifier",
      description: "Convert JSON data into a plain string format easily and instantly.",
      keyword: "json to string converter, json to string online, convert json to string, json stringify, format json as string"
    },
    "/string-to-json-convertor": {
      title: "String to JSON Converter - Data Beautifier",
      description: "Convert a plain string into a structured JSON format quickly.",
      keyword: "string to json converter, string to json online, convert string to json, json parse, parse string to json"
    },
    "/json-to-html-convertor": {
      title: "JSON to HTML Converter - Data Beautifier",
      description: "Transform JSON data into an HTML format with ease.",
      keyword: "json to html converter, json to html online, convert json to html, json to html table, json formatter to html"
    },
    "/html-minifier": {
      title: "HTML Minifier - Data Beautifier",
      description: "Minify and compress your HTML code for better performance.",
      keyword: "html minifier, html minify online, minify html, compress html, optimize html code"
    },
    "/base64-to-ascii-conversion": {
      title: "Base64 to ASCII Converter - Data Beautifier",
      description: "Decode Base64 encoded data into readable ASCII format instantly.",
      keyword: "base64 to ascii converter, base64 decode, decode base64 to ascii, convert base64 to ascii"
    },
    "/ascii-to-base64-conversion": {
      title: "ASCII to Base64 Converter - Data Beautifier",
      description: "Encode ASCII text into Base64 format effortlessly.",
      keyword: "ascii to base64 converter, ascii to base64 encode, convert ascii to base64, base64 encoding"
    }

  };

  // Default values if route is not found
  const defaultMeta = {
    title: "Best JSON Validator and JSON Formatter - Data Beautifier",
    description: "Use Data Beautifier to validate, format, and minify JSON, XML, and other data formats with ease.",
    keyword: "json formatter, json beautifier, json validator, online json formatter, json formatter online, json beautify, beautify json, format json, JSON Cleaner, json converter, json format, json online format, format json online"
  };

  const { title, description, keyword } = routeMeta[location.pathname] || defaultMeta;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://databeautifier.com${location.pathname}`} />
    </Helmet>
  );
};

export default TitleManager;

