import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const AboutPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div class="w3-row-padding mar-top-80">
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <h3 class="">ABOUT THE COMPANY</h3>
              <p class="w3-large">Key features of our company</p>
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
            <div class="w3-row-padding" style={{ margin: '14px 0px 100px 0px' }}>
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
                
                <p>Welcome! We're group of people, and software engineers with a love for processing data in various format. Our journey started back in 2007, and over the years, We've had the pleasure of exploring and growing in the world of modern technology like API, React, React JS, Nest and Node etc.</p>

                <p>Driven by curiosity and a passion for data conversion and processing, We've worked on most of the API based project and everywhere we got the data either in JSON or XML format. Whether it's where it's crusial to process those data and convert them to desire format., We're always excited to learn and push the boundaries of what's possible.</p>

                <p>Beyond our professional life, We're deeply passionate about learning new technology and developing online tools which can helps other developers to do their task easily, effectivaly. We believe that "creativity thrives in collaboration" or "curiosity fuels growth", and We aim to live that out every day—whether through our work or in our everyday adventures.</p>

                <p>Feel free to explore our site.</p>

              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutPage;