import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
const FAQPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div class="w3-row-padding mar-top-80">
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <h3 class="">FAQ</h3>
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
            <div class="w3-row-padding" style={{ margin: '14px 0px 100px 0px' }}>
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
                
                <b>1. What do you do?</b>
                <p>I am a [your profession/role, e.g., freelance designer, content creator, software developer], and I specialize in [brief description of your key services, like "building custom websites" or "writing engaging blog content"]. You can check out my [portfolio/work samples] for examples of my past projects.</p>

<p><b>2. How can I work with you?</b>
If you're interested in collaborating or hiring me for a project, simply head over to my [Contact Page] or send me an email at [your email]. We can discuss your needs and determine how I can help bring your ideas to life.</p>

<b>3. What services do you offer?</b>
<p>I offer a variety of services, including:

[Service 1, e.g., Web Design & Development]
[Service 2, e.g., Content Creation]
[Service 3, e.g., Consulting or Coaching]
If you're looking for something specific that isn't listed, feel free to reach out, and we can see if it''s a good fit.</p>
<b>4. What is your pricing?</b>
<p>Pricing depends on the scope and complexity of each project. After an initial consultation, I'll provide you with a customized quote based on your specific needs. Feel free to contact me to discuss your project in more detail.</p>

<b>5. How long does it take to complete a project?</b>
<p>The timeline varies depending on the size and nature of the project. Typically, smaller projects may take [mention timeframe, e.g., "1-2 weeks"], while larger projects could take [mention timeframe, e.g., "a few months"]. We'll agree on a timeline during the initial consultation to ensure everything stays on track.</p>

<b>6. Do you offer revisions?</b>
<p>Yes, I believe in delivering quality work that meets your expectations. I typically offer [mention number] rounds of revisions as part of my services to ensure you're happy with the final product.</p>

<b>7. Can you help with [related task]?</b>
<p>Absolutely! I'm always open to new challenges. If you need help with something outside my typical services, feel free to ask. I'll let you know if I can assist or refer you to someone who can.</p>

<b>8. Do you work remotely?</b>
<p>Yes! I work with clients from all over the world. Thanks to digital tools and communication platforms, distance is never an issue. We can easily collaborate through [mention tools you use, like email, Zoom, Slack].</p>

<b>9. How can I stay updated on your work?</b>
<p>You can follow me on [social media platform, e.g., Instagram/Twitter/LinkedIn] for updates, or subscribe to my [newsletter/blog] for the latest news, tips, and projects I'm working on.</p>

<b>10. What inspires your work?</b>
<p>I draw inspiration from [mention sources of inspiration, e.g., nature, technology, people's stories, or the ever-changing world of design]. I'm passionate about [specific areas of interest, e.g., "creating meaningful experiences" or "solving complex problems through creativity"].</p>
                
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default FAQPage;