// src/pages/Dashboard.js

import React, { useState, useRef } from 'react';
import '../css/site.css';
import '../css/w3.css';
import '../css/dashboard.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { copyToClipboard, handleDownload, zoomIn, zoomOut, openFullscreen } from '../Helpers/CommonHelpers';
import { Conversions } from '../Conversion/Conversions';

const Dashboard = () => {
  const [inputJson, setInputJson] = useState('');
  const [outputData, setOutputData] = useState('');
  const [isError, setIsError] = useState(false);
  const [fontSize, setFontSize] = useState(16); // Default font size
  const [tabSpace, setTabSpace] = useState(2); // Default tab space
  const [inputCollapsed, setInputCollapsed] = useState([]);
  const [outputCollapsed, setOutputCollapsed] = useState([]);
  const [inputLnCol, setInputLnCol] = useState({ line: 1, column: 1 });
  const [outputLnCol, setOutputLnCol] = useState({ line: 1, column: 1 });


  const inputRef = useRef(null);
  const outputRef = useRef(null);

  const toggleCollapse = (line, isInput) => {
    const collapsed = isInput ? inputCollapsed : outputCollapsed;
    const setCollapsed = isInput ? setInputCollapsed : setOutputCollapsed;
    if (collapsed.includes(line)) {
      setCollapsed(collapsed.filter((l) => l !== line));
    } else {
      setCollapsed([...collapsed, line]);
    }
  };

  const getLineNumbers = (jsonString, collapsedLines, isInput) => {
    const lines = jsonString.split("\n");
    const result = [];

    let currentDepth = 0;
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const trimmedLine = line.trim();

      // Detect JSON object or array start
      if (trimmedLine.endsWith("{") || trimmedLine.endsWith("[")) {
        currentDepth++;
        result.push(
          <div key={i} style={{  alignItems: "center" }}>
            <button
              onClick={() => toggleCollapse(i + 1, isInput)}
              style={{
                all: "unset",
                cursor: "pointer",
                fontSize: "12px",
                marginRight: "5px",
              }}
              title={collapsedLines.includes(i + 1) ? "Expand" : "Collapse"}
            >
              <FontAwesomeIcon
                icon={collapsedLines.includes(i + 1) ? faCaretRight : faCaretDown}
              />
            </button>
            {i + 1}
          </div>
        );
      } else if (trimmedLine.endsWith("}") || trimmedLine.endsWith("]")) {
        currentDepth--;
        result.push(<div key={i}>{i + 1}</div>);
      } else {
        result.push(<div key={i}>{i + 1}</div>);
      }
    }
    return result;
  };

  const handleCopy = () => {
    copyToClipboard(outputData);
  };

  const handleConversion = () => {
    Conversions(inputJson, setOutputData, setIsError, tabSpace);
  };

  const updateCursorPosition = (textarea, setLnCol) => {
    const text = textarea.value.substring(0, textarea.selectionStart);
    const lines = text.split("\n");
    const line = lines.length;
    const column = lines[lines.length - 1].length + 1;
    setLnCol({ line, column });
  };


  return (
    <div>
      <Header />

      <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
        <div className="w3-margin-bottom">
        <h2 className="title-heading">
        Online Tools for Data Formatting, Minification, and Conversion
        </h2>
        <p className="title-subheading">
        Data Beautifier is your one-stop destination for all your data formatting needs. Our website offers an extensive range of tools, including Data Formatting Tools, Online Minification Tools, Base64 Tools, JSON and HTML Beautifier, and Online Code Formatter. Simplify complex tasks like converting JSON to XML, XML to JSON, and minifying or beautifying your code with just a few clicks. Designed with user convenience in mind, Data Beautifier ensures quick, accurate results without hassle. Whether you're a developer or data analyst, our free tools make managing data effortless. Visit Data Beautifier and streamline your workflow today!
          </p>

        </div>
        <div className="w3-row">
          <div className="w3-col m5">
            <div className="editor-container">
              <div className="line-numbers" id="input-line-numbers">
                {getLineNumbers(inputJson, inputCollapsed, true)}
              </div>
              <div className="textarea-wrapper">
                <textarea
                  className="w3-input w3-border textarea editor-textarea"
                  placeholder="Input JSON"
                  required
                  name="Input"
                  value={inputJson}
                  onChange={(e) => {
                    setInputJson(e.target.value);
                    setIsError(false);
                  }}
                  onScroll={(e) => {
                    const lineNumbersDiv = document.getElementById("input-line-numbers");
                    lineNumbersDiv.scrollTop = e.target.scrollTop; // Sync scroll
                  }}
                  onKeyUp={(e) => updateCursorPosition(e.target, setInputLnCol)}
                  onClick={(e) => updateCursorPosition(e.target, setInputLnCol)}
                  ref={inputRef}
                />
                <div className="status-bar">
                  <span>Ln: {inputLnCol.line}, Col: {inputLnCol.column}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="w3-margin-bottom">
              <select
                id="tabSpace"
                value={tabSpace}
                onChange={(e) => setTabSpace(Number(e.target.value))}
                className="w3-select"
              >
                <option value={2}>2 Spaces</option>
                <option value={3}>3 Spaces</option>
                <option value={4}>4 Spaces</option>
              </select>
            </div>
            <button
              className="w3-button w3-blue w3-margin-top"
              style={{ display: 'block', width: '50%' }}
              onClick={handleConversion}
            >
              Convert
            </button>

            <button
              className="w3-button w3-blue w3-margin-top"
              style={{ display: 'block', width: '50%' }}
              onClick={() => handleDownload(outputData)}
            >
              Download
            </button>
          </div>
          <div className="w3-col m5">
            <div style={{ position: 'relative' }}>
              <div className="editor-container">
                <div className="line-numbers" id="output-line-numbers">
                  {getLineNumbers(outputData, outputCollapsed, false)}
                </div>
                <div className="textarea-wrapper">
                  <textarea
                    className="w3-input w3-border output-textarea editor-textarea"
                    placeholder="Output"
                    required
                    name="Output"
                    value={outputData}
                    readOnly
                    style={{ color: isError ? 'red' : 'black', fontSize: `${fontSize}px` }}
                    onScroll={(e) => {
                      const lineNumbersDiv = document.getElementById("output-line-numbers");
                      lineNumbersDiv.scrollTop = e.target.scrollTop; // Sync scroll
                    }}
                    onKeyUp={(e) => updateCursorPosition(e.target, setOutputLnCol)}
                    onClick={(e) => updateCursorPosition(e.target, setOutputLnCol)}
                    ref={outputRef}
                  />
                  <div className="status-bar">
                    <span>Ln: {outputLnCol.line}, Col: {outputLnCol.column}</span>
                  </div>
                </div>
              </div>
              <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={handleCopy}
                  title="Copy To Clipboard"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={() => zoomIn(setFontSize)}
                  title="Zoom In"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={() => zoomOut(setFontSize)}
                  title="Zoom Out"
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={openFullscreen}
                  title="View Full Screen"
                >
                  <FontAwesomeIcon icon={faExpand} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Dashboard;