// src/XmlFormatter.js
import React, { useState, useRef } from "react";
import { formatXml } from "../Conversion/XmlFormat";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { copyToClipboard, zoomIn, zoomOut, openFullscreen, handlexmlDownload } from '../Helpers/CommonHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faDownload } from '@fortawesome/free-solid-svg-icons';

const XmlFormatter = () => {
    const [inputXml, setInputXml] = useState("");
    const [formattedXml, setFormattedXml] = useState("");
    const [fontSize, setFontSize] = useState(14);
    const outputRef = useRef(null);

    const handleFormat = () => {
        const formatted = formatXml(inputXml);
        setFormattedXml(formatted);
    };

    const handleCopy = () => {
        copyToClipboard(formattedXml);
    };

    return (
        <div>
            <Header />
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
                <h1 className="title-heading">
                Format XML
                </h1>
                <p className="title-subheading">
                Simplify your XML formatting tasks with Data Beautifier's Free XML Formatter Online Tool. Our Best XML Formatter Online Tool organizes and beautifies your XML data for better readability and structure. Designed for user convenience, the Simple XML Formatter Tool requires no technical knowledge, making it ideal for developers and non-technical users alike. With the Easy XML Formatter Tool, you can format your XML files in just a few clicks, ensuring clean and well-structured data every time. Accessible 24/7 and completely free, Data Beautifier is your go-to solution for effortless XML formatting. Try it today and streamline your workflow!
                </p>
                <div className="w3-row">
                    <div className="w3-col m5">

                        <textarea
                            className="w3-input w3-border textarea"
                            rows="28"
                            placeholder="Enter your XML here..."
                            value={inputXml}
                            onChange={(e) => setInputXml(e.target.value)}
                        />
                    </div>
                    <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <button onClick={handleFormat} disabled={!inputXml.trim()} className="mar-top-80">
                            Format XML
                        </button>
                    </div>
                    <div className="w3-col m5">
                        <div style={{ position: 'relative' }}>
                            <textarea
                                className="w3-input w3-border output-textarea textarea"
                                rows="30"
                                value={formattedXml}
                                readOnly
                                placeholder="Formatted XML will appear here..."
                                ref={outputRef}
                                style={{ fontSize: `${fontSize}px`, width: '100%' }}
                            />
                            <div style={{ position: 'absolute', top: '65px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={handleCopy} title="Copy To Clipboard">
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomIn(setFontSize)} title="Zoom In">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomOut(setFontSize)} title="Zoom Out">
                                    <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => openFullscreen(outputRef)} title="View Full Screen">
                                    <FontAwesomeIcon icon={faExpand} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginTop: '5px' }} onClick={() => handlexmlDownload(formattedXml)} title="Download File">
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default XmlFormatter;
