export const minifyJSON = (inputData) => {
  try {
    return { minified: JSON.stringify(JSON.parse(inputData), null, 0), isError: false };
  } catch (error) {
    return { minified: 'Error: Invalid JSON input.', isError: true };
  }
};

export const minifyXML = (inputData) => {
  try {
    return { minified: inputData.replace(/\s+/g, ' ').trim(), isError: false };
  } catch (error) {
    return { minified: 'Error: Invalid XML input.', isError: true };
  }
};

export const minifyHTML = (inputData) => {
  try {
    return { minified: inputData.replace(/\s+/g, ' ').trim(), isError: false };
  } catch (error) {
    return { minified: 'Error: Invalid HTML input.', isError: true };
  }
};
