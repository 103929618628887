import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faDownload } from '@fortawesome/free-solid-svg-icons';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { copyToClipboard, handleDownloadTxt, zoomIn, zoomOut, openFullscreen } from '../Helpers/CommonHelpers';
import { convertJsonToString } from '../Conversion/JsonStringConvertor';

const JsonToString = () => {

    const [inputData, setInputData] = useState('');
    const [outputData, setOutputData] = useState('');
    const [isError, setIsError] = useState(false);
    const [fontSize, setFontSize] = useState(14);
    const inputRef = useRef(null);
    const outputRef = useRef(null);

    useEffect(() => {
        setInputData('');
        setOutputData('');
        setIsError(false);
    }, []);


    const handleConversion = () => {
        const { convertedData, isError } = convertJsonToString(inputData);
        setOutputData(convertedData);
        setIsError(isError);
    };


    const handleCopy = () => {
        copyToClipboard(outputData);
    };

    return (
        <div>
            <Header />
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }}>
                <h2 className="title-heading">
                    Convert JSON to String
                </h2>

                <p className="title-subheading">

                    <p>
                        Looking for a Free JSON to String Conversion Online Tool? Data Beautifier makes it simple and efficient! Our Best JSON to String Converter Online Tool is designed to transform JSON data into a properly formatted string seamlessly. With our Simple JSON to String Converter Tool, you can handle conversions in just a few clicks—no technical expertise required. Whether you’re a developer or a data enthusiast, our Easy JSON to String Converter Tool ensures accuracy and speed, saving you valuable time. Accessible anytime, Data Beautifier provides reliable results for free. Simplify your data conversion needs with us today!                        </p>
                </p>

                <div className="w3-row">
                    <div className="w3-col m5">
                        <div className="textarea-container">
                            <textarea
                                className="w3-input w3-border textarea"
                                rows="30"
                                placeholder={`Input JSON`}
                                value={inputData}
                                onChange={(e) => setInputData(e.target.value)}
                                ref={inputRef}
                                style={{ width: '100%', marginBottom: '20px' }}
                            />
                        </div>

                    </div>
                    <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button
                            className="w3-button w3-blue w3-margin-top"
                            style={{ width: '50%', margin: '20px' }}
                            onClick={handleConversion}
                        >
                            Convert
                        </button>
                    </div>
                    <div className="w3-col m5">
                        <div style={{ position: 'relative' }}>
                            <div className="textarea-container">
                                <textarea
                                    className="w3-input w3-border output-textarea textarea"
                                    rows="30"
                                    placeholder="Output"
                                    value={outputData}
                                    readOnly
                                    style={{ color: isError ? 'red' : 'black', fontSize: `${fontSize}px`, width: '100%' }}
                                    ref={outputRef}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={handleCopy} title="Copy To Clipboard">
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomIn(setFontSize)} title="Zoom In">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomOut(setFontSize)} title="Zoom Out">
                                    <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => openFullscreen(outputRef)} title="View Full Screen">
                                    <FontAwesomeIcon icon={faExpand} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginTop: '5px' }} onClick={() => handleDownloadTxt(outputData)} title="Download File">
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default JsonToString;