import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSync } from '@fortawesome/free-solid-svg-icons';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { convertJsonToHtml, downloadHtmlFile } from '../Conversion/JsonHtmlConvertor';


const JsonToHtml = () => {

    const [jsonInput, setJsonInput] = useState('');
    const [htmlOutput, setHtmlOutput] = useState('');


    return (
        <div>
            <Header />
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }}>
                <h2 className="title-heading">
                    Convert JSON to Html
                </h2>
                <p className="title-subheading">
                    <p>
                        Looking for a Free JSON to HTML Conversion Online Tool? Data Beautifier makes it simple and efficient! Our Best JSON to HTML Converter Online Tool is designed to transform JSON data into a properly formatted HTML structure seamlessly. With our Simple JSON to HTML Converter Tool, you can handle conversions in just a few clicks—no technical expertise required. Whether you’re a developer or a data enthusiast, our Easy JSON to HTML Converter Tool ensures accuracy and speed, saving you valuable time. Accessible anytime, Data Beautifier provides reliable results for free. Simplify your data conversion needs with us today!</p>
                </p>

                <textarea 
                    rows="8" 
                    className="w3-input w3-border" 
                    placeholder="Paste your JSON here..." 
                    value={jsonInput} 
                    onChange={(e) => setJsonInput(e.target.value)}
                ></textarea>
                
                <button className="w3-button w3-green w3-margin-top" onClick={() => setHtmlOutput(convertJsonToHtml(jsonInput))}>
                    <FontAwesomeIcon icon={faSync} /> Convert
                </button>
                
                {htmlOutput && (
                    <div className="w3-margin-top">
                        <h3>Converted HTML Output:</h3>
                        <div className="w3-panel w3-white w3-border" style={{ overflowX: 'auto', padding: '10px' }}>
                            <div dangerouslySetInnerHTML={{ __html: htmlOutput }} />
                        </div>
                        <button className="w3-button w3-blue w3-margin-top" onClick={() => downloadHtmlFile(htmlOutput)}>
                            <FontAwesomeIcon icon={faDownload} /> Download HTML
                        </button>
                    </div>
                )}

            </div>
            <Footer />
        </div>
    );
}

export default JsonToHtml;